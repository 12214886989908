<template>
  <div class="register" v-if="step === 'register'">
    <div class="user-top">
      <van-config-provider :theme-vars="themeVars">
        <van-nav-bar left-arrow @click-left="goBack" :border="false" />
      </van-config-provider>
    </div>
    <div class="user-wrap">
      <div class="user-wrap-title">{{$t('title.register')}}</div>
      <van-form @submit="doRegister">
        <div class="field-wrap">
          <van-field
            v-model="sendTo"
            name="sendTo"
            class="user-wrap-field with-prefix"
            type="tel"
            size="large"
            clearable
            :placeholder="$t('placeholder.phone')"
          />
          <van-field
            :model-value="columns[areaCodeIndex]"
            class="user-wrap-field-prefix"
            readonly
            @click="checkShowPicker"
          />
        </div>
        <div class="field-wrap">
          <van-field
            v-model="verifyCode"
            class="user-wrap-field with-suffix"
            size="large"
            type="digit"
            maxlength="6"
            clearable
            :placeholder="$t('placeholder.verifyCode')"
          />
          <van-button v-if="coldDownTime === 0" type="primary" class="sms-btn" :disabled="smsDisabled" @click="sendVerifyCode">{{$t('button.smsBtn')}}</van-button>
          <van-button v-else type="primary" class="sms-btn" :disabled="true">{{$t('button.smsColdDown')}} {{coldDownTime}}{{$t('button.second')}}</van-button>
        </div>
        <div class="field-wrap">
          <van-field
            v-model="password"
            name="password"
            class="user-wrap-field"
            size="large"
            type="password"
            clearable
            :placeholder="$t('placeholder.password')"
          />
        </div>
        <div class="field-wrap">
          <van-field
            v-if="showInviteCode"
            v-model="inviteCode"
            name="inviteCode"
            class="user-wrap-field"
            size="large"
            clearable
            :placeholder="$t('placeholder.inviteCode')"
          />
        </div>
        <div style="margin-top: 30px;">
          <van-button
            native-type="submit"
            size="large"
            type="primary"
            block
            :disabled="registerDisabled"
          >{{$t('button.confirm')}}</van-button>
          <van-button
            to="login"
            size="large"
            color="#000"
            style="margin-top: 12px;"
            replace
            block
            plain
            hairline
          >{{$t('button.goToLogin')}}</van-button>
        </div>
      </van-form>
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
          :columns="columns"
          @confirm="selectCode"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import md5 from 'js-md5';
import i18n, { Local } from '@/assets/lang/index';
import { getAreaCode, sendRegisterVerifyCode, register } from "@/api";
  
export default {
  setup() {
    const sendTo = ref('');
    const columns = ref([]);
    const areaCodeList = ref([]);
    const areaCodeIndex = ref(0);
    const inviteCode = ref('');
    const showPicker = ref(false);
    const selectCode = (value, index) => {
      areaCodeIndex.value = index;
      showPicker.value = false;
    };
    onMounted(() => {
      getAreaCode().then(res => {
        areaCodeList.value = res.data || [];
        columns.value = (res.data || []).map(item => item.name);
      })
    })
    
    const themeVars = {
      navBarBackgroundColor: 'transparent',
    };
    const verifyCode = ref('');
    const password = ref('');
    return { sendTo, columns, areaCodeList, areaCodeIndex, inviteCode, selectCode, showPicker, themeVars, verifyCode, password };
  },
    
  data() {
    return {
      step: 'register',
      coldDownTime: 0,
      coldDownInterval: null,
      showInviteCode: true,
    };
  },
  
  computed: {
    smsDisabled() {
      if (this.areaCodeList.length > 0) {
        const regex = this.areaCodeList[this.areaCodeIndex].regex;
        return !this.sendTo.match(regex);
      } else {
        return true;
      }
    },
    registerDisabled() {
      if (this.areaCodeList.length > 0) {
        const regex = this.areaCodeList[this.areaCodeIndex].regex;
        return !this.sendTo.match(regex) || !this.inviteCode || this.verifyCode.length != 6 || !this.password;
      } else {
        return true;
      }
    },
  },
  
  mounted() {
    const search = window.location.search.split('?')[1];
    const urlString = search ? search.split('&') : [];
    const urlParam = {};
    for(var i = 0; i < urlString.length; i++) {
      const _param = urlString[i].split('=');
      urlParam[_param[0]] = _param[1];
    }
    if (urlParam.code) {
      this.inviteCode = urlParam.code;
      this.showInviteCode = false;
    }

  },

  methods: {
    sendVerifyCode() {
      if (!this.inviteCode) {
        this.$toast({ type: 'danger', message: i18n.global.t('placeholder.inviteCode') });
        return false;
      }
      sendRegisterVerifyCode({
        phone: this.sendTo,
        areaCode: this.areaCodeList[this.areaCodeIndex].code,
        code: this.inviteCode,
      }).then(() => {
        this.coldDownTime = 60;
        this.coldDown();
      });
    },
    doRegister() {
      register({
        username: this.sendTo,
        areaCode: this.areaCodeList[this.areaCodeIndex].code,
        invitationCode: this.inviteCode,
        verifyCode: this.verifyCode,
        loginPassword: md5(this.password),
      }).then(res => {
        const { userInfo, pageInfoList } = res.data || {};
        let tabListConfig = {
          appPageInfoList: pageInfoList.sort((a, b) => a.tab - b.tab),
        };
        this.$AppData.saveConfig(tabListConfig);
        
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        if (localStorage.getItem('i18n') != userInfo.local) {
          Local(userInfo.local);
        }
        this.$router.replace('workbench');
      });
    },
    checkShowPicker() {
      if(this.columns.length > 1) {
        this.showPicker = true;
      } 
    },
    goBack() {
      if (this.step == 'register') {
        this.$router.replace('login');
      } else {
        this.verifyCode = '';
        this.step = 'register';
      }
    },
    coldDown() {
      if (this.coldDownInterval) {
        clearInterval(this.coldDownInterval);
      }
      this.coldDownInterval = setInterval(() => {
        this.coldDownTime = this.coldDownTime - 1;
        if (this.coldDownTime ===0) {
          clearInterval(this.coldDownInterval);
        }
      }, 1000)
    },
  },
};
</script>

<style lang="less">
.register {
  background: #fff url(/images/top-bg.png) no-repeat;
  background-size: 180px auto;
  background-position: right top;
  .user-top {
    height: 83px;
  }
  .user-wrap {
    padding: 40px 26px 0;
    height: calc(100vh - 83px);
    box-sizing: border-box;
    &-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 52px;
    }
    &-subtitle {
      font-size: 14px;
      color: #8F90A6;
      margin-bottom: 22px;
      letter-spacing: 0;
    }
    .field-wrap { position: relative; margin-top: 20px; }
    &-field {
      font-size: 18px;
      border: 1px solid #C1C1D6;
      border-radius: 2px;
      &.with-prefix { padding-left: 74px; }
      &.with-suffix { padding-right: 130px; }
    }
    &-field-prefix {
      position: absolute;
      left: 16px;
      top: 13px;
      padding: 0;
      width: 50px;
      font-size: 18px;
    }
    .triangle {
      position: absolute;
      width: 8px;
      left: 54px;
      top: 22px;
    }
    .sms-btn {
      position: absolute;
      right: 4px;
      top: 7px;
      height: 36px;
    }
    .extra-link {
      margin-top: 20px;
      text-align: right;
      font-size: 14px;
      color: #8F90A6;
      cursor: pointer;
      &.is-link {
        color: var(--van-primary-color);
      }
    }
    .van-password-input {
      margin: 0;
      li {
        border: 1px solid #ddd;
        border-radius: 2px;
      }
    }
  }
}
</style>
